<template>
  <div class="upload flex" ref="img-frm">
    <el-upload
      :class="{limited: fileList.length === newLimit}"
      action="xx"
      list-type="picture-card"
      :on-preview="handlePreview" 
      :multiple="true"
      :accept="uploadAccept"
      :http-request="uploadImg"
      :before-upload="beforeUpload"
      :before-remove="beforeRemove"
      :on-remove="removeImg"
      :file-list="fileList"
      :limit="newLimit"
    >
      <div class="upload-btn">
        <div><i class="el-icon-plus"></i></div>
        <div>上传图片</div>
      </div>
    </el-upload>
    <div class="hint">
      <div>
        1.图片大小建议不超过3MB；
        700*700 以上图片上传。
        2.图片长宽比例1：1
      </div>
      <div v-if="newLimit" class="imgLength">{{(fileList || '').length}}/{{newLimit}}</div>
    </div>

    <!-- 多图片预览弹窗 -->
    <el-dialog :append-to-body="true" :modal-append-to-body="false" width="40%" :title="popTitle" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>
<script>
import { IMG_URL_PRE } from "@/config";
import { uploadFile, delFile } from "@/service/common";
export default {
  model: {
    prop: "imgs",
    event: "change"
  },
  props: ["imgs", "limit", "size", "prefix"],
  data() {
    return {
      popTitle:"预览图片",
      dialogVisible: false, // 展示弹窗开关
      dialogImageUrl: '', // 多图情况弹窗内图片链接
      imgUrl: IMG_URL_PRE,
      uploadAccept: "image/jpeg,image/gif,image/png",
      newLimit: 5,
      fileList: []
    };
  },
  // watch: {
  //   imgs: {
  //     handler(newName, oldName) {
  //       this.initV();
  //     },
  //     deep: true
  //   }
  // },
  created() {
    this.initV();
    // console.log(this.limit);
    // console.log(this.imgs);
  },
  methods: {
    initV() {
        if (this.limit) {
            this.newLimit = this.limit;
        }
        if (this.imgs && this.imgs.length > 0) {
            for (let img of this.imgs) {
                if (img.indexOf(this.imgUrl) == -1) {
                    this.fileList.push({ url: this.imgUrl + img });
                } else {
                    this.fileList.push({ url: img });
                }
            }
        }
    },
    onChange() {
      let _imgs = this.fileList.reduce((pre, cur) => {
        let url = cur.url.replace(this.imgUrl, "");
        pre.push(url);
        return pre;
      }, []);
      this.$emit("change", _imgs);
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < (this.size || 5);
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 " + (this.size || 5) + "MB!");
      }
      return isLt2M;
    },
    uploadImg(item) {
      uploadFile(item.file, this.prefix, {
        onUploadProgress: event => {
          item.file.percent = (event.loaded / event.total) * 100;
          item.onProgress(item.file);
        }
      })
        .then(url => {
          this.fileList.push({
            status: "success",
            uid: new Date().getTime(),
            url: IMG_URL_PRE + url
          });
          this.onChange();
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    handlePreview (file) {
      // 点击进行图片展示
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    //删除图片钩子
    beforeRemove(file, fileList) {
      let a = true;
      if (file && file.status==="success") {
        a = this.$confirm("确定删除该图片？","温馨提示");
      }
      return a;
    },
    //删除图片
    removeImg(file, fileList) {
      if (file && file.status==="success") {
        this.fileList = fileList;
        this.onChange();
        this.$message.success("删除成功");
      }
      // const _loading = this.$loading({
      //   target: this.$refs["img-frm"],
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading"
      // });
      // delFile(file.url).then(() => {
      //   this.fileList = fileList;
      //   this.onChange();
      //   _loading.close();
      // }).catch(err => {
      //   console.log(err);
      //   _loading.close();
      //   this.$message.error(err.message);
      // });
    }
  }
};
</script>
<style scoped>
.upload>>> .el-dialog__wrapper {
  line-height: 30px;
}
.upload>>> .el-dialog__header {
    line-height: 24px;
}
.upload>>> .el-upload {
  position: relative;
}
.upload>>> .el-upload--picture-card {
  line-height: 25px;
}
.upload-btn {
  position: absolute;
  top: calc(50% - 28px);
  left: calc(50% - 28px);
  color: #5074ee;
}
.upload-btn i {
  font-size: 24px;
  color: #5074ee;
}
.limited >>> .el-upload {
  display: none;
}
.upload {
  position: relative;
}
.hint {
  width: 165px;
  font-size: 12px;
  color: #808080;
  line-height: 20px;
  padding-left: 16px;
}
.imgLength {
  font-size: 14px;
  font-weight: 500;
  color: #808080;
  line-height: 20px;
  width: 50px;
  padding: 68px 0 0 5px;
  /* position: absolute;
    right: 0;
    bottom: 0; */
}
</style>